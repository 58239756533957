import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  setDoc,
  doc,
} from "firebase/firestore";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAIO7IFDt6TWKzAT2ZPBseROUqJnhP_fWU",
  authDomain: "grupodemilhoes.firebaseapp.com",
  projectId: "grupodemilhoes",
  storageBucket: "grupodemilhoes.appspot.com",
  messagingSenderId: "74114699355",
  appId: "1:74114699355:web:b7228191556e4ff1adef7e",
  measurementId: "G-C5D3GCCQ08",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);

    if (docs.docs.length === 0) {
      await setDoc(
        doc(db, "users", user.uid),
        {
          nome: user.displayName,
          
          email: user.email,
          ultimoAcesso: new Date().toISOString(),
        },
        { merge: true }
      );
    }
  } catch (err) {
    console.error(err);

    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export { auth, db, signInWithGoogle, logout };
