import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, signInWithGoogle } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Loading from "./Loading";
import { google } from "boxicons";

function Login() {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      <Loading />;
      return;
    }
    if (user) navigate("/app/welcome");
  }, [user, loading]);
  return (
    <div className="min-h-screen bg-white flex items-center">
      <div className="card mx-auto w-50 max-w-5xl shadow-xl">
        
      <div className="card card-body">
        <div className="card card-title">
          <h1 className="text-2xl">
            Bem vindo ao Grupo de Milhões
          </h1>
        </div>
        <div className="card card-content"><p>Faça o seu login e descubra um universo de novas possibilidades</p></div>
          <button className="btn btn-rounded" onClick={signInWithGoogle}>
            <box-icon type="logo" name="google" color="white"></box-icon>
            ENTRE COM SUA CONTA GOOGLE
          </button>
        </div>
      </div>
    </div>
  );
}
export default Login;
