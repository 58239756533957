import React, { lazy, useEffect } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { themeChange } from 'theme-change'
import checkAuth from './app/auth';
import initializeApp from './app/init';
import { QueryClient, QueryClientProvider } from 'react-query'
import Login from './components/Login/Login';
import Reset from './components/Login/Reset';

const USER_TYPES = {
  ADMIN: 'admin',
  INFLUENCER: 'influencer',
  CONTRATANTE: 'contratante',
}

const CURRENT_USER_TYPE = USER_TYPES.PUBLIC;

// Importing pages
const Layout = lazy(() => import('./containers/Layout'))
// const Login = lazy(() => import('./pages/Login'))
const Documentation = lazy(() => import('./pages/Documentation'))
const queryClient = new QueryClient()

// Initializing different libraries
initializeApp()


// Check for login and initialize axios
const token = checkAuth()


function App() {

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false)
  }, [])


  return (
    <>
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          
          
          
          
          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />

          <Route path="*" element={<Navigate to={token ? "/app/welcome" : "/login"} replace />}/>

        </Routes>
      </Router>
      </QueryClientProvider>
    </>
  )
}

function AdminElement({children}) {
  if (CURRENT_USER_TYPE === USER_TYPES.ADMIN)
  return <>{children}</>;
}



export default App
